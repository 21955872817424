'use client';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { usePathname, useSearchParams, useParams } from 'next/navigation';
import { useEffect } from 'react';
import { IUser } from '@/types';
import { POSTHOG_HOST, POSTHOG_KEY } from '@/config/environment';

if (typeof window !== 'undefined') {
  posthog.init(POSTHOG_KEY, {
    api_host: POSTHOG_HOST,
  });
}

const searchParamsToObject = (searchParams: URLSearchParams) => {
  const searchParamsObject: { [key: string]: string } = {};
  searchParams.forEach((value, key) => {
    searchParamsObject[key] = value;
  });
  return searchParamsObject;
};

export default function PHProvider({ user, children }: { user: IUser; children: React.ReactNode }) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const params = useParams();

  // Track pageviews
  useEffect(() => {
    if (pathname) {
      let url = window.origin + pathname;
      if (searchParams?.toString()) {
        url = url + `?${searchParams?.toString()}`;
      }
      posthog.capture('$pageview', {
        $current_url: url,
        path_params: params,
        search_params: searchParamsToObject(searchParams),
      });
    }
  }, [pathname, searchParams, params]);

  const userId = user?.id;
  const userEmail = user?.email;
  const userFullName = user?.name;
  const userName = user?.username;

  useEffect(() => {
    if (userId) {
      posthog.identify(
        userId, // Replace 'distinct_id' with your user's unique identifier
        { username: userName, email: userEmail, name: userFullName },
      );
    }
  }, [userId, userName, userEmail, userFullName]);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
