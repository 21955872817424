import(/* webpackMode: "eager" */ "/app/node_modules/@vercel/analytics/dist/react/index.js");
import(/* webpackMode: "eager" */ "/app/src/app/Providers.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/ToasterComponent.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/FlashMessage/FlashMesage.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/MaintenancePage/styled.ts");
import(/* webpackMode: "eager" */ "/app/src/components/MessageModal/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/lib/StyledComponentsRegistry.tsx");
import(/* webpackMode: "eager" */ "/app/src/styles/normalize.css");
import(/* webpackMode: "eager" */ "/app/src/styles/vars-fonts.css");
import(/* webpackMode: "eager" */ "/app/src/styles/react-popup.css");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts/index.tsx\",\"import\":\"Roboto_Flex\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"Roboto\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts/index.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"display\":\"swap\"}],\"variableName\":\"Inter\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts/index.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./bwseidoraw-regular-webfont.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./bwseidoraw-medium-webfont.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./bwseidoraw-bold-webfont.woff2\",\"weight\":\"bold\",\"style\":\"normal\"},{\"path\":\"./bwseidoraw-black-webfont.woff2\",\"weight\":\"800\",\"style\":\"normal\"}],\"variable\":\"--font-bwseido-raw\"}],\"variableName\":\"BWseidoRaw\"}");
import(/* webpackMode: "eager" */ "/app/src/ui/Footer/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/ui/Header/BaseHeader/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/ui/Header/MaintenanceHeader/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/ui/Layout/styled.ts");
import(/* webpackMode: "eager" */ "/app/src/ui/PageLayout/styled.ts")